import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import APP_CONST from '@/constants/AppConst';

class SCHOLAR_UTILITIES extends Vue {
    bxHeaders = [];
    csvHeaders: any = [];
    getGlobalState = getModule(GlobalModule, store);

    markRequiredLabel(requiredlabel: string[], bxLabels: any[]) {
      bxLabels.forEach((el: any) => {
        el['isHeaderMandatory'] = requiredlabel.includes(el.columnName);
      });
      return bxLabels;
    }


    nonMatchedCsvHeaders(labels: string[]) {
      const availableCsvHeaders: string[] = [];
      this.csvHeaders.forEach((el: any) => {
        if (!(labels.some((label: any) => label.rowIndex === el.rowIndex))) {
          availableCsvHeaders.push(el.columnName);
        }
      });
      return availableCsvHeaders;
    }

    labelWiseMatchedArray(labels: any[]) {
      const unMatchedHeader = this.nonMatchedCsvHeaders(labels);
      labels.forEach((el: any) => {
        for (const csvEl of this.csvHeaders) {
          if (el.rowIndex === csvEl.rowIndex) {
            el['matchedArray'] = csvEl.columnName
              ? ['Select', csvEl.columnName]
              : ['Select'];
            el['selectedOption'] = csvEl.columnName;
            break;
          }
          else {
            el['matchedArray'] = ['Select', ...unMatchedHeader];
            el['selectedOption'] = 'Select';
          }
        }
      });
      return { bxHeaders: labels, unMatchedHeader };
    }

    unresolvedLabelIds(groupedLabel: any) {
      const unResolvedArray: any[] = [];
      for (const group in groupedLabel) {
        groupedLabel[group].forEach((label: any) => {
          if (label.columnName !== label.selectedOption) {
            unResolvedArray.push(label.groupName + '_' + label.rowIndex);
          }
        });
      }
      return unResolvedArray;
    }

    groupingBxHeaders(allHeaders: { bxHeaders: any[]; csvHeaders: any[] }, requiredlabels: string[]) {
      const labels = JSON.parse(JSON.stringify(allHeaders.bxHeaders));
      this.csvHeaders = allHeaders.csvHeaders;
      const markedLabels = this.markRequiredLabel(requiredlabels, labels);
      const { bxHeaders, unMatchedHeader } = this.labelWiseMatchedArray(markedLabels);
      const groupBy = (array: any[], key: string) => {
        return array.reduce((result, currentValue) => {
          if (!result[currentValue[key]]) {
            result[currentValue[key]] = [];
          }
          result[currentValue[key]].push(currentValue);
          return result;
        }, {}); 
      };
      const GroupedByLabel = groupBy(bxHeaders, 'groupName');
      return { GroupedByLabel, unMatchedHeader };
    }

    validateObj(groupedHeader: any) {
      let valid = true;
      for (const group in groupedHeader) {
        groupedHeader[group].forEach((label: any) => {
          if (label.isHeaderMandatory && (!label.selectedOption || label.selectedOption === 'Select')) {
            valid = false;
          }
        });
      }
      return valid;
    }


    checkForCustomDropdown(groupedHeader: any) {
      let addCustom = false;
      let customCount = 0;
      for (const group in groupedHeader) {
        groupedHeader[group].forEach((label: any) => {
          if (label.isCustom) {
            customCount += 1;
          }
        });
      }
      if (customCount === 6) {
        addCustom = false;
      }
      else {
        addCustom = true;
      }
      return addCustom;
    }

    generateAlphabets(rowIndex: string) {
      const alphabetArr = rowIndex.split('');
      let lastAlpha = alphabetArr[alphabetArr.length - 1];
      const charCode = lastAlpha.charCodeAt(0) + 1;
      if (lastAlpha !== 'Z') {
        lastAlpha = String.fromCharCode(charCode);
        alphabetArr.pop();
        alphabetArr.push(lastAlpha);
      }
      else {
        if (alphabetArr.length > 1) {
          const secondLast = alphabetArr[alphabetArr.length - 2];
          const secondLastCharCode = secondLast.charCodeAt(0) + 1;
          const secondLastAlpha = String.fromCharCode(secondLastCharCode);
          alphabetArr[alphabetArr.length - 2] = secondLastAlpha;
          alphabetArr[alphabetArr.length - 1] = 'A';

        }
        else {
          lastAlpha = 'A';
        }
      }
      return alphabetArr.join('');
    }

    createAutoSaveStructure(groupedHeader: any) {
      const finalizeStructure: any[] = [];
      for (const group in groupedHeader) {
        groupedHeader[group].forEach((label: any) => {
          finalizeStructure.push({ isCustom: label.isCustom, rowIndex: label.rowIndex, bxLabel: label.columnName, csvLabel: label.selectedOption });
        });
      }
      return finalizeStructure;
    }

    updateOption(groupedHeader: any, option: string[]) {
      for (const group in groupedHeader) {
        groupedHeader[group].forEach((label: any) => {
          if (label.remainingArray) {
            label.remainingArray = option;
          }
        });
      }
      return groupedHeader;
    }

    getBXheaderCsvValue(rowIndex: any, step2data: any) {
      const optionToSelectIndex = step2data.findIndex((el: any) => el.rowIndex === rowIndex);
      let csvLabel = 'Select';
      if (optionToSelectIndex >= 0) {
        csvLabel = step2data[optionToSelectIndex].csvLabel;
      }
      return csvLabel;
    }

    updateGroupedHeader(groupedHeader: any, step2data: any) {
      for (const group in groupedHeader) {
        groupedHeader[group].forEach((label: any) => {
          label['selectedOption'] = this.getBXheaderCsvValue(label.rowIndex, step2data);
          if (label['selectedOption'] && label['selectedOption'] !== 'Select') {
            label['matchedArray'] = ['Select', label['selectedOption']];
          }
          else {
            label['selectedOption'] = 'Select';
          }
        });
      }
      return groupedHeader;
    }

    reduceUnmatchedOptions(unmatchedHeaders: string[], obj: any) {
      const groupwiseIndex = obj.updatedGroupedHeader[obj.groupKey].findIndex((el: any) => el.rowIndex === obj.rowIndex);
      let index = -1;
      const option = obj.option as [];
      index = unmatchedHeaders.findIndex((el: any) => el === obj.selectedOption);
      if (index >= 0) {
        unmatchedHeaders.splice(index, 1);
      }
      if (obj.selectedOption && obj.selectedOption !== 'Select') {
        obj.updatedGroupedHeader[obj.groupKey][groupwiseIndex].matchedArray = ['Select', obj.selectedOption];
      }
      else {
        let prevOptionToAdd = '';
        if(groupwiseIndex >= 0) {
          if(!obj.updatedGroupedHeader[obj.groupKey][groupwiseIndex]['matchedArray']) {
            obj.updatedGroupedHeader[obj.groupKey][groupwiseIndex]['matchedArray'] = [];
          }
          if(obj.option.length === APP_CONST.TWO) {
            prevOptionToAdd = obj.option[APP_CONST.ONE];
            obj.updatedGroupedHeader[obj.groupKey][groupwiseIndex]['matchedArray'] = ['Select', prevOptionToAdd ,...unmatchedHeaders]; 
          }
          else {
            obj.updatedGroupedHeader[obj.groupKey][groupwiseIndex]['matchedArray'] = ['Select', ...unmatchedHeaders];
          }
        }
      }
      if(!obj.selectedOption || (obj.selectedOption === 'Select')) {
        option.forEach((el: any) => {
          if(el !== 'Select') {
            unmatchedHeaders.push(el);
          }
        });
      }


      const groupedHeaders = JSON.parse(JSON.stringify(obj.updatedGroupedHeader));
      for (const group in groupedHeaders) {
        groupedHeaders[group].forEach((label: any) => {
          if (!label['selectedOption'] || (label['selectedOption'] === 'Select')) {
            label['matchedArray'] = ['Select', ...unmatchedHeaders];
          }
        });
      }
      return { unmatchedHeaders, groupedHeaders };
    }

    unmatchedOptionList(groupedHeaders: any, unMatchedHeader: string[], step2data: any) {
      const unMatchedHeaders = JSON.parse(JSON.stringify(unMatchedHeader));
      step2data.forEach((el: any) => {
        for (const group in groupedHeaders) {
          groupedHeaders[group].forEach((label: any) => {
            if ((el.csvLabel === 'Select') && (el.rowIndex === label.rowIndex)) {
              unMatchedHeaders.push(label.matchedArray[APP_CONST.ONE]);
            }
          });
        }
      });

      if(unMatchedHeaders.length) {
        for (const group in groupedHeaders) {
          groupedHeaders[group].forEach((label: any) => {
            if (unMatchedHeaders.includes(label.selectedOption)) {
              const index = unMatchedHeaders.findIndex((el: any) => el === label.selectedOption);
              unMatchedHeaders.splice(index, 1);
            }
          });
        }
      }

      for(const custom of step2data) {
        if(custom.isCustom && custom.csvLabel && (custom.csvLabel !== 'Select')) {
          if(unMatchedHeaders.includes(custom.csvLabel)) {
            const index = unMatchedHeaders.findIndex((el: any) => el === custom.csvLabel);
            unMatchedHeaders.splice(index, 1);
          }
        }
      } 

      for (const group in groupedHeaders) {
        groupedHeaders[group].forEach((label: any) => {
          if(!label.selectedOption || (label.selectedOption === 'Select')) {
            if(unMatchedHeaders.length) {
              label.matchedArray = ['Select', ...unMatchedHeaders];
            }
            else {
              label.matchedArray = ['Select'];
            }
          }
        });
      }
        
      if(!groupedHeaders['Custom Fields']) {
        groupedHeaders['Custom Fields'] = [];
      }

      for(const custom of step2data) {
        if(custom.isCustom) {
          if(!custom.csvLabel || (custom.csvLabel !== 'Select')) {
            groupedHeaders['Custom Fields'].push({index: 0, rowIndex: custom.rowIndex, groupName: 'Custom Fields',
              columnName: custom.bxLabel,isCustom: APP_CONST.TRUE, isHeaderMandatory: APP_CONST.FALSE,
              selectedOption: custom.csvLabel, matchedArray: ['Select', custom.csvLabel ]});
          }
          else {
            groupedHeaders['Custom Fields'].push({index: 0, rowIndex: custom.rowIndex, groupName: 'Custom Fields',
              columnName: custom.bxLabel,isCustom: APP_CONST.TRUE, isHeaderMandatory: APP_CONST.FALSE,
              selectedOption: custom.csvLabel, matchedArray: ['Select',  ...unMatchedHeaders ]});
          }
        }
      } 
      return {groupedHeaders, unMatchedHeaders};
    }

    checkForMatchingCompletion(groupedHeaders: any) {
      let columnscompletelyMatched = true;
      for (const group in groupedHeaders) {
        groupedHeaders[group].forEach((label: any) => {
          if (!label.selectedOption || (label.selectedOption.toLowerCase() === 'select')) {
            columnscompletelyMatched = false;
          }
        });
        if(!columnscompletelyMatched){
          break;
        }
      }
      return columnscompletelyMatched;
    }
    
}

export const scholarUtility = new SCHOLAR_UTILITIES();