import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import scholar from '@/store/modules/scholarManager';
import staff from '@/store/modules/staffManager';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import { scholarUtility } from '@/utilities/scholarStuff';
import APP_UTILITIES from '@/utilities/commonFunctions';

import store from '@/store';


@Component({})
export default class DropdownComponent extends Vue {
    searchOption: string = '';
    columnMatched: boolean = false;
    option: string[] = [];
    visibility: boolean = false;
    clearSearch: boolean = false;
    getGlobalState = getModule(GlobalModule, store);
    isSelected:boolean = false;

    @Prop()
    mappedData!:[];

    @Prop({default: ''})
    page_title! : string;

    @Prop()
    options!: [];

    @Prop()
    label!: any;

    @Prop()
    groupKey!: string;

    @Watch('options', { deep: true, immediate: true })
    optionsChanged(newVal: any) {
      this.option = newVal;
      this.validateLabels();
    }
    

    clearSearchInput() {
      this.searchOption = '';
      this.search();
    }

    search() {
      this.clearSearch = this.searchOption
        ? APP_CONST.TRUE
        : APP_CONST.FALSE;
      this.option = this.options.filter((el: any) => {
        if (el.columnName.toLowerCase().includes(this.searchOption.toLowerCase())) {
          return true;
        }
        else {
          return false;
        }
      }) as [];
    }

    selectVal(value: any) {
      if(this.label.selectedOption === value.columnName) {
        this.visibility = !this.visibility;
        return;
      }
      this.label.selectedOption = value.columnName;
      if(value.columnName =='Select'){
        if(this.label.csvObject.rowIndex){
          const obj:any = {
            value,
            label:this.label.csvObject,
            headerRowIndex:this.label.rowIndex
          };
          this.$emit('fillUnMappedList',obj);
        }
      }
      else if(value.columnName !=='Select'){
        const obj:any = {
          value,
          label:this.label.csvObject,
          headerRowIndex:this.label.rowIndex
        };
        this.$emit('fillUnMappedList',obj);
      }
      this.saveAutoRoaster(this.label.rowIndex);
      if(value.columnName!=='Select'){
        this.isSelected= true;
      }
      else{
        this.isSelected= false;
      }
      this.visibility = !this.visibility;
    }

    checkColumnsMatch(rightLabel: string) {
      this.columnMatched = rightLabel !== 'Select'
        ? APP_CONST.TRUE
        : APP_CONST.FALSE;
      const purelyMatchedColumn = this.label.columnName === rightLabel
        ? APP_CONST.TRUE
        : APP_CONST.FALSE;
      if (!purelyMatchedColumn) {
        this.manipulateOption(rightLabel);
      }
    }

    setVisibility() {
      this.visibility = false;
    }

    manipulateOption(rytLabel: string) {
      this.option = JSON.parse(JSON.stringify(this.options));
      if (rytLabel !== 'Select') {
        this.option = this.option.filter(e => e !== rytLabel);
      }
    }

    validateLabels() {
      const currentGroupedHeader = this.getGlobalState.groupedHeaders;
      const valid = scholarUtility.validateObj(currentGroupedHeader);
      this.$store.dispatch('globalModule/isValidStep2', valid);
    }

    configureAutoRoaster() {
      const groupedHeader = this.getGlobalState.groupedHeaders;
      const index = groupedHeader[this.groupKey].findIndex((el: any) => (el.rowIndex === this.label.rowIndex) && el.columnName && (el.columnName !== 'Select'));
      if (index >= 0) {
        groupedHeader[this.groupKey][index].selectedOption = this.label.selectedOption;
        if(this.label.selectedOption === 'Select' && this.label.isCustom === APP_CONST.TRUE) {
          groupedHeader[this.groupKey][index].columnName = 'Custom';
        }
        else if(this.label.selectedOption !== 'Select' && this.label.isCustom === APP_CONST.TRUE) {
          groupedHeader[this.groupKey][index].columnName = this.label.selectedOption;
        }
      }

      this.$store.dispatch('globalModule/setGroupedHeaders', groupedHeader);
    }

    removeCustomHeaders(bxRowIndex:any) {
      const rowId : any = document.getElementById('row-'+bxRowIndex);
      rowId.classList.add('uk-animation-reverse');
      setTimeout(() => {
        const deleteIndex:any = this.mappedData.findIndex((item:any) => item[0].rowIndex == bxRowIndex);
        if(deleteIndex!==-1 &&this.label.selectedOption!=='Select'){
          const objSend:any = JSON.parse(JSON.stringify(this.mappedData[deleteIndex][1]));
          this.$emit('fillUnMappedList',{...objSend,maxCustReached:true});
        }
        else if(deleteIndex!==-1 &&this.label.selectedOption==='Select'){
          this.$emit('fillUnMappedList',{maxCustReached:true});
        }
        if(document.querySelectorAll('.Custom Fields').length==1){
          if(document.querySelectorAll('#custom-heading').length>0){
            document.querySelectorAll('#custom-heading')[APP_CONST.ZERO].remove();
          }
        }
        this.isSelected= false;
        this.mappedData.splice(deleteIndex,1);
        this.$emit('updateUnresolved'); 
        this.saveAutoRoaster(true);
      },200);
    }

    


    saveAutoRoaster(labeRowIndex:any) {
      const groupedHeader = this.getGlobalState.groupedHeaders;
      const { programId, siteId, accountId} = APP_UTILITIES.coreids();
        
      const step2ArrayFill:any = [];
      this.mappedData.forEach((item:any) => {
        const obj:any ={
          bxLabel:item[0].columnName,
          csvLabel: item[1].columnName,
          isCustom: item[0].isCustom
            ? true
            :false,
          isManuallyMapped: item[1].isManuallyMapped
            ?true
            :  labeRowIndex == item[0].rowIndex,
          rowIndex: item[0].rowIndex,
          csvRowIndex:item[1].rowIndex
            ?item[1].rowIndex
            :''
        };
        step2ArrayFill.push(obj);          
      });
        
      if(this.page_title === 'staff-roster') {
        const configureObj: {
                'siteId': number;
                'programId': number;
                'isFinalSubmit': boolean;
                'rosterStep': number;
                'step2': string;
            } = {
              'siteId': siteId,
              'programId': programId,
              'isFinalSubmit': false,
              'rosterStep': 2,
              'step2': '',
            };
        configureObj.step2 = JSON.stringify(step2ArrayFill);
        staff.autoSaveStaffRoster(configureObj).then((response: any) => {});
      }
      else {
        const configureObj: {
                'siteId': number;
                'accountId': number;
                'programId': number;
                'isFinalSubmit': boolean;
                'rosterStep': number;
                'step2': any[];
            } = {
              'siteId': this.getGlobalState.siteId,
              'accountId': accountId,
              'programId': programId,
              'isFinalSubmit': false,
              'rosterStep': 2,
              'step2': [],
            };
        configureObj.step2 = step2ArrayFill;
        scholar.configureScholarRoaster(configureObj).then((response: any) => {
          const siteId = this.getGlobalState.siteId;              
        });
      }
        
    }


}