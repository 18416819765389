import { downloadTemplate, requiredHeaders, scholarStatus, getHeaders, configureRoaster,getTableDetails, getAutoSaveTableDetails,
  getAutoSaveStep2, deleteRoasterAPI } from '@/services/scholar/scholarService';
import store from '@/store';
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

  @Module({
    namespaced: true,
    name: 'scholar',
    store,
    dynamic: true
  })
class ScholarModule extends VuexModule {
    public downloadRes: any = {};
    public requiredHeaderRes: any = {};
    public scholarRoasterRes: any = {};
    public headerResponse: any = {};
    public configureRoasterRes: any = {};
    public tableGetResponse:any = {};
    public getAutoSaveStep2: any = {};
    public deletedRoasterRes: any = {};

    

    get tableData(){
      return this.tableGetResponse;
    }

    get downloadResponse(){
      return this.downloadRes;
    }

    get bxMustHeaders(){
      return this.requiredHeaderRes;
    }

    get ScholarStatus(){
      return this.scholarRoasterRes;
    }

    get bxCsvHeaders(){
      return this.headerResponse;
    }

    get ConfigRoast(){
      return this.configureRoasterRes;
    }

    get autoSaveForStep2(){
      return this.getAutoSaveStep2;
    }

    @Mutation
    public setDownload(payload:any) {
      this.downloadRes = payload;
    }
  

    @Action({commit: 'setDownload'})
    public download():Promise<Object>{
      const resultNew = async function downloadTemplates(): Promise<Object> {
        try {
          const promise = downloadTemplate();
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500};
        }
      };
      return resultNew();
    }

    @Mutation
    public setrequiredHeader(payload:any) {
      this.requiredHeaderRes = payload;
    }
  

    @Action({commit: 'setrequiredHeader'})
    public bxrequiredHeaders():Promise<Object>{
      const resultNew = async function downloadTemplates(): Promise<Object> {
        try {
          const promise = requiredHeaders();
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500};
        }
      };
      return resultNew();
    }


    @Mutation
    public getScholarStatus(payload:any) {
      this.requiredHeaderRes = payload;
    }
  

    @Action({commit: 'getScholarStatus'})
    public checkScholarStatus(sideId: number):Promise<Object>{
      const resultNew = async function extractScholarStatus(Id: number): Promise<Object> {
        try {
          const promise = scholarStatus(Id);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500};
        }
      };
      return resultNew(sideId);
    }

    @Mutation
    public setBxCsvHeaders(payload:any) {
      this.headerResponse = payload;
    }
  

    @Action({commit: 'setBxCsvHeaders'})
    public getAllHeaders(sideId: number):Promise<Object>{
      const resultNew = async function Headers(Id: number): Promise<Object> {
        try {
          const promise = getHeaders(Id);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500};
        }
      };
      return resultNew(sideId);
    }

    @Mutation
    public mutateRoasterState(payload:any) {
      this.configureRoasterRes = payload;
    }
  
    
    @Mutation
    public setTableData(payload:any) {
      this.tableGetResponse = payload;
    }
  

    @Action({commit: 'setTableData'})
    public fetchTableDetails(stepObj:any):Promise<Object>{
      const resultNew = async function Table(stepObj:any): Promise<Object> {
        try {
          const promise = getTableDetails(stepObj);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500} as any;
        }
      };
      return resultNew(stepObj);
    }

    
    @Action({commit: 'setTableData'})
    public fetchAutoSaveTable(stepObj:any):Promise<Object>{
      const resultNew = async function Table(stepObj:any): Promise<Object> {
        try {
          const promise = getAutoSaveTableDetails(stepObj);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500} as any;
        }
      };
      return resultNew(stepObj);
    }

    @Action({commit: 'mutateRoasterState'})
    public configureScholarRoaster(payload: {}):Promise<Object>{
      const resultNew = async function configure(obj: {}): Promise<Object> {
        try {
          const promise = configureRoaster(obj);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500} as any;
        }
      };
      return resultNew(payload);
    }

    @Mutation
    public autoSaveRes(payload:any) {
      this.getAutoSaveStep2 = payload;
    }
  

    @Action({commit: 'setTableData'})
    public getStep2AutoSave(payload: any):Promise<Object>{
      const resultNew = async function getAutoSave(payload2: any): Promise<Object> {
        try {
          const promise = getAutoSaveStep2(payload2);
          const result = await promise;  
          return result as object;
        }
        catch {
          return {status: 500} as any;
        }
      };
      return resultNew(payload);
    }

    @Mutation
    public mutateDeletedRoaster(payload:any) {
      this.deletedRoasterRes = payload;
    }
   
    @Action({commit:'mutateDeletedRoaster'})
    public async deleteRoaster(siteId:number):Promise<Object>{
      try {
        const promise = deleteRoasterAPI(siteId);
        const result = await promise;  
        return result as object;
      }
      catch {
        return {status: 500} as any;
      }
        
    }

  }
export default getModule(ScholarModule);
